#logout:hover{
color: white;
background-color: green;
}
.nav-link{
    font-size: 20px;
}
.nav-link:hover{
    font-size: 22px;
}
.greet{
    display: flex;
    
    
}
.name{
    margin-left: 30px;
}
.navbar-transparent {
    background-color: #fff;
  }
/* Custom CSS for responsive hamburger icon */
@media (min-width: 500px) {
    .hamburger-icon {
      display: none;
    }
  }
  
  /* Show hamburger icon only on mobile or smaller screens */
  @media (max-width: 500px) {
    .hamburger-icon {
      display: block;
    }
  }
  