.containers {
  padding: 20px;
  background-color: aliceblue;
}


.mt-4 {
  margin-top: 16px;
}

.mt-4, .mt-4 {
  font-size: 24px;
  font-weight: bold;
}

.mb-4, .section-heading {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: bold;
}

.form-input {
  margin-bottom: 16px;
}

.file-input {
  margin-bottom: 16px;
}

.create-button {
  margin-top: 16px;
}

.card-styles {
  margin-bottom: 24px;
  /* padding: 16px; */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-title {
  margin-bottom: 8px;
  font-size: 38px;
  font-weight: bold;
  background-color:powderblue;
  padding: 5px;
  border-radius: 10px;
}

.post-image {
  width: auto;
  height: 200px; 
  margin-bottom: 16px;
  object-fit: cover;
}



.post-description {
  font-size: 14px;
  color: #888;
  /* display: flex; */

}

.delete-button {
  margin-top: 8px;
}

.cardss {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.card-styles {
  width: 80vw;
}
.parent-card {
  background-color: aliceblue;
  display: flex;
  flex-direction: column;
}
a{
  text-decoration: none;
  color: black;
}


@media screen and (max-width:600px){
  .Desc{

      display: flex;
      overflow-x: auto;

  }
}